<template>
	<template-base class="containerCard">
		<div>
			<div class="container-head">
				<div
					v-if="possuiPermissao('GER_I_TIPOS')"
					class="container-add-new"
					v-bind:class="!tiposContas.length ? 'container-add-new-100' : ''"
				>
					<b-button class="add-button" @click="mostrarEdicao({})">
						Adicionar
					</b-button>
				</div>
			</div>
			<div v-if="tiposContas.length" class="head-row mb-2 width-pagination-items">
				<div class="text-head-row width-pagination">
					<label class="text-head-row ml-0">
						Resultado {{ pagination.offset + 1 }}-{{ pagination.offset + tiposContas.length }} de {{ pagination.count }}
					</label>
					<b-pagination
						class="mr-0 mb-2"
						id="page"
						v-model="currentPage"
						:total-rows="pagination.count"
						:per-page="pagination.limit.value"
					></b-pagination>
				</div>
				<b-form
					class="text-head-row width-pagination">
					<label class="text-head-row ml-0">
						Itens por página
					</label>
					<b-form-select
						class="mr-0 mb-2"
						v-model="pagination.limit.value"
						name="itens-por-pagina"
						:options="optionsItensPorPag"
						@input="changeItensByPage()"
						:disabled="loading"
					/>
				</b-form>
			</div>
			<b-modal
				ref="modalEdicao"
				hide-footer
				:title="titleModalEdicao"
				size="md"
			>
				<div class="modal-edicao d-block mb-3 modal-edicao-label">
					<b-row>
						<b-col cols="12" class="d-block text-tipo-conta">
							<span class="label-tipo-conta">Tipo de Conta</span>
							<b-form-input
								name="tipo_conta"
								class="modal-edicao-input"
								type="text"
								v-model="tipoContaSelecionado.description"
								autocomplete="off"
								placeholder="Digite o nome do tipo da conta"
								v-validate="{ required: true }"
								:state="validateState('tipo_conta')"
							/>
						</b-col>
					</b-row>
					<b-row class="filters-orientation">
						<b-col class="d-block text-tipo-conta">
							<span class="label-tipo-conta">Fechamento Mensal</span>
							<ToggleSwitch
								name="tipo-conta-close-monthly"
								class="toggle-tipo-conta"
								:value="tipoContaSelecionado.closeMonthly"
								@toggle="_ => changeToggle(tipoContaSelecionado.closeMonthly, 'closeMonthly')"
							/>
						</b-col>
						<b-col class="d-block text-tipo-conta">
							<span class="label-tipo-conta">Pago Automatico</span>
							<ToggleSwitch
								name="tipo-conta-pago-automatico"
								class="toggle-tipo-conta"
								:value="tipoContaSelecionado.automaticPay"
								@toggle="_ => changeToggle(tipoContaSelecionado.automaticPay, 'automaticPay')"
							/>
						</b-col>
					</b-row>
				</div>
				<b-button class="ml-1 mt-3 float-left btn-modal btn-cancel" @click="fecharModal('modalEdicao')">Cancelar</b-button>
				<b-button class="mt-3 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
				<b-button
					class="mt-3 ml-1 float-right btn-modal btn-salvar"
					@click="salvarEdicao"
					:disabled="invalidForm"
				>
					Salvar
				</b-button>
			</b-modal>
		</div>
		<div v-if="tiposContas.length" class="table-responsive">
			<DataTable
				class="data-table"
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:nosearch="true"
				:nofilters="true"
				name="tiposContas"
				:hasPagination="true"
				:noedit="true"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_TIPOS')"
				:permissaoDelete="possuiPermissao('GER_D_TIPOS')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
				@clickSortTableBy="(v) => sortTableBy(v)"
				:state="{
					sortBy: sortTable.sortBy,
					sortAsc: sortTable.order === 1,
					query: ''
				}"
				:async="true"
			/>
		</div>
		<div v-else class="alert alert-warning" role="alert">
			{{ errMsg }}
		</div>
	</template-base>
</template>

<script>
	import ToggleSwitch from "@/components/ToggleSwitch";

	import DataTable from "@/components/DataTable";
	import TemplateBase from "@/templates/Base";

	import { possuiPermissao } from "../../helpers/permissions";
	import { AccountClassService } from "../../services/accountClass";
	import { hasFieldsWithErrors } from "@/helpers/validators";

	export default {
		components: {
			TemplateBase,
			ToggleSwitch,
			DataTable
		},

		inject: ["parentValidator"],

		data () {
			return {
				tiposContas: [],
				linhas: [],
				titleModalEdicao: "",
				loading: true,
				tipoContaSelecionado: {
					idAccountClass: "",
					description: "",
					closeMonthly: false,
					automaticPay: false
				},

				invalidForm: true,
				accountClassService: new AccountClassService(),

				errMsg: "Nenhum tipo de contas cadastrada!",
				colunas: [
					"Tipo de Conta",
					"Fechamento Mensal",
					"Pago Automatico"
				],
				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],
				possuiPermissao,
				sortTable: {
					sortBy: 2,
					order: -1
				}
			};
		},

		watch: {
			currentPage () {
				this.changePage(this.currentPage);
			}
		},

		created () {
			this.$validator = this.parentValidator;
		},

		async mounted () {
			this.findTiposContas();
		},

		methods: {
			sortTableBy (v) {
				this.sortTable = v;
				this.findTiposContas();
			},

			changePage () {
				this.pagination.page = this.currentPage;
				this.findTiposContas();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findTiposContas();
			},

			async findTiposContas () {
				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;
				const sortTable = this.sortTable;

				const result = await this.accountClassService.listAccountClass({offset, limit, sortTable}) || {};
				this.tiposContas = result.rows || [];
				this.linhas = result.rows.reduce((acc, gu) => [
					...acc,
					{
						idAccountClass: gu.idAccountClass,
						cols: [
							gu.description,
							gu.closeMonthly ? "Sim" : "Não",
							gu.automaticPay ? "Sim" : "Não"
						]
					}
				], []);

				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
			},

			mostrarEdicao (item_) {
				let item = {};
				if (item_.idAccountClass)
					[ item ] = this.tiposContas.filter(gu => gu.idAccountClass === item_.idAccountClass);

				const { idAccountClass, description, closeMonthly, automaticPay } = item;

				if (!idAccountClass)
					this.titleModalEdicao = "Cadastrar Novo Tipo de Contas";
				else
					this.titleModalEdicao = "Editar Tipo de Contas";

				this.invalidForm = true;
				this.tipoContaSelecionado.idAccountClass = idAccountClass || "";
				this.tipoContaSelecionado.description = description || "";
				this.tipoContaSelecionado.closeMonthly = closeMonthly || false;
				this.tipoContaSelecionado.automaticPay = automaticPay || false;
				this.$refs.modalEdicao.show();
			},

			async confirmaApagar (item_) {
				const [ item ] = this.tiposContas.filter((gu) => gu.idAccountClass === item_.idAccountClass);
				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja remover o tipo de conta?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.idAccountClass);
			},

			async salvarEdicao () {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				const payload = {
					idAccountClass: this.tipoContaSelecionado.idAccountClass,
					description: this.tipoContaSelecionado.description,
					closeMonthly: this.tipoContaSelecionado.closeMonthly,
					automaticPay: this.tipoContaSelecionado.automaticPay
				};

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						await this.accountClassService.upsertAccountClass({payload});

						this.fecharModal("modalEdicao");
						this.findTiposContas();

						return {
							title: "Sucesso!",
							body: "Tipo de conta salvo com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar salvar tipo de conta!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
			},

			async apagar (idAccountClass) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						await this.accountClassService.deleteAccountClass({idAccountClass});
						this.findTiposContas();

						return {
							title: "Sucesso!",
							body: "Tipo de conta deletada com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar deletar o tipo de conta!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			limparModal () {
				this.tipoContaSelecionado.description = "";
				this.tipoContaSelecionado.closeMonthly = false;
				this.tipoContaSelecionado.automaticPay = false;
				this.invalidForm = true;
			},

			changeToggle (status, field) {
				if (field === "closeMonthly") {
					this.tipoContaSelecionado.closeMonthly = !status;
					if (this.tipoContaSelecionado.closeMonthly && this.tipoContaSelecionado.automaticPay) this.tipoContaSelecionado.automaticPay = false;
				} else {
					this.tipoContaSelecionado.automaticPay = !status;
					if (this.tipoContaSelecionado.automaticPay && this.tipoContaSelecionado.closeMonthly) this.tipoContaSelecionado.closeMonthly = false;
				}
				this.invalidForm = !this.tipoContaSelecionado.description;
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			}
		}
	};
</script>

<style scoped>
	.text-tipo-conta {
		font-family: 'Roboto Condensed Regular';
		font-size: 20px;
	}

	.label-tipo-conta {
		color: #696969 !important;
	}

	.toggle-tipo-conta {
		margin-top: 7px;
	}

	label {
		width: 160px;
	}

	.container-head {
		display: flex;
		height: 5rem;
		width: 100%;
	}

	.container-pages {
		display: flex;
		width: 70%;
		margin-top: 1.5rem;
	}

	.containerCard {
		max-width: 90.5%;
	}

	.modal-edicao-label {
		font-family: 'Roboto Condensed Regular';
		font-size: 23px;
	}

	.modal-edicao-input {
		font-family: 'Roboto Condensed Regular';
		font-size: 20px;
	}

	.table-body-detalhe {
		padding: 5px 0;
	}

	.container-add-new-100 {
		width: 100% !important;
	}
</style>
